import '../sass/project.scss';


// Import all of Bootstrap's JS
import { Alert, Collapse, Tooltip, Popover } from 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css';
window.bootstrap = {
  Alert, Collapse, Tooltip, Popover
}

/* Project specific Javascript goes here. */
